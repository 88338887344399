import Status from '@/api/models/Status'
import { Module } from 'vuex'
import { State } from '@/store'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export interface InstallerState {
    statuses: Array<Status>;
}

const state: InstallerState = {
    statuses: []
}

export const installerStore: Module<InstallerState, State> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
