import { ActionContext, ActionTree } from 'vuex'
import { State } from '@/store'
import { InstallerState } from '@/modules/installer/store/index'
import ReferenceService from '@/api/services/ReferenceService'

const referenceService = new ReferenceService()

export const actions: ActionTree<InstallerState, State> = {
    async loadStatuses (context: ActionContext<InstallerState, State>) {
        const statuses = await referenceService.statusesList()

        context.commit('setStatuses', statuses)
    }
}
