import Vue from 'vue'
import { PluginObject } from 'vue/types/umd'
import App from './App.vue'
import router from './router'
import store from './store'
import sentryInit from '@/sentry'

/* eslint-disable-next-line */
// @ts-ignore
import UiLibrary from 'vue-ui-kit/dist/ui-kit.common'
import 'vue-ui-kit/dist/ui-kit.css'
import i18n from './i18n'

Vue.config.productionTip = false
sentryInit()

/* eslint-disable-next-line */
Vue.use(UiLibrary as PluginObject<Record<string, any>>)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
