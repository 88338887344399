import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import connectRouting from '@/modules/connect/routing'
import installerRouting from '@/modules/installer/routing'
import settingsRouting from '@/modules/settings/routing'

import i18n from '@/i18n'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '*',
        meta: { title: i18n.t('notFound') },
        component: () => import('@/shared/views/NotFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.concat(connectRouting, installerRouting, settingsRouting)
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router
