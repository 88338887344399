import BaseService from '@/api/services/index'
import Account from '@/api/models/Account'
import Statuses from '@/api/models/Statuses'
import AccountSettings from '@/api/models/AccountSettings'
import StoresSettings from '@/api/models/StoresSettings'

export default class AccountService extends BaseService {
    public async createAccount (account: Account): Promise<Account> {
        return await this.api.post<Account, Account>(
            this.getUrl('create_account'),
            account
        )
    }

    public async updateAccount (account: Account): Promise<Account> {
        return await this.api.patch<Account, Account>(
            this.getUrl('update_account', { accountId: account.id }),
            account
        )
    }

    public async updateStatuses (accountId: number, statuses: Statuses): Promise<Statuses> {
        return await this.api.put<Statuses, Statuses>(
            this.getUrl('update_account_statuses', { accountId: accountId }),
            statuses
        )
    }

    public async updateSettings (accountId: number, settings: AccountSettings): Promise<AccountSettings> {
        delete settings.account

        return await this.api.put<AccountSettings, AccountSettings>(
            this.getUrl('update_account_settings', { accountId: accountId }),
            settings
        )
    }

    public async updateStores (accountId: number, stores: StoresSettings): Promise<StoresSettings> {
        return await this.api.put<StoresSettings, StoresSettings>(
            this.getUrl('update_account_stores', { accountId: accountId }),
            stores
        )
    }
}
