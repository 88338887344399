import { ActionContext, ActionTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'
import ConnectionService from '@/api/services/ConnectionService'
import AccountService from '@/api/services/AccountService'
import ReferenceService from '@/api/services/ReferenceService'
import { RootStateMutations } from '@/store/mutations'
import AccountSettings from '@/api/models/AccountSettings'
import StoresSettings from '@/api/models/StoresSettings'

const service = new ConnectionService()
const accountService = new AccountService()
const referenceService = new ReferenceService()

export const actions: ActionTree<State, State> = {
    async signIn (context: ActionContext<State, State>, connection: Connection) {
        const newConnection = await service.signIn(connection)

        const conn = Object.assign({}, newConnection)

        context.commit(RootStateMutations.setAccounts, Object.assign([], conn.accounts))
        delete conn.accounts
        context.commit(RootStateMutations.setConnection, conn)
    },
    async auth (context: ActionContext<State, State>, connection: Connection) {
        await service.auth(connection)
    },
    async loadConnection (context: ActionContext<State, State>) {
        const connection = await service.getConnection()

        const conn = Object.assign({}, connection)

        context.commit(RootStateMutations.setConnection, conn)
        context.commit(RootStateMutations.setAccounts, Object.assign([], conn.accounts))
    },
    async updateApiKey (context: ActionContext<State, State>, apiKey: string) {
        await service.updateConnection({
            apiKey,
            apiUrl: context.state.connection.apiUrl
        } as Connection)
    },
    async createAccount (context: ActionContext<State, State>, account: Account) {
        const newAccount = await accountService.createAccount(account)

        context.commit(RootStateMutations.addAccount, newAccount)
        await context.dispatch('selectAccount', newAccount)
    },
    async updateAccount (context: ActionContext<State, State>, account: Account) {
        const resultAccount = await accountService.updateAccount(account)

        context.commit(RootStateMutations.updateAccount, resultAccount)
    },
    async loadSites (context: ActionContext<State, State>) {
        const sites = await referenceService.sitesList()

        context.commit(RootStateMutations.setSites, sites)
    },
    async loadPriceTypes (context: ActionContext<State, State>) {
        const priceTypes = await referenceService.priceTypesList()

        context.commit(RootStateMutations.setPriceTypes, priceTypes)
    },
    async loadStores (context: ActionContext<State, State>, account: number) {
        const stores = await referenceService.storeList(account)

        context.commit(RootStateMutations.setStores, stores)
    },
    async selectAccount (context: ActionContext<State, State>, account: Account) {
        context.commit(RootStateMutations.setAccount, account.id)
    },
    async updateAccountSettings (context: ActionContext<State, State>, settings: AccountSettings) {
        if (context.state.currentAccount) {
            const accountSettings = await accountService.updateSettings(context.state.currentAccount, settings)

            const accounts = context.state.accounts
            accounts.forEach((account: Account, index: number) => {
                if (account.id === context.state.currentAccount) {
                    accounts[index].settings = accountSettings
                }
            })

            context.commit(RootStateMutations.setAccounts, accounts)
        }
    },
    async updateStores (context: ActionContext<State, State>, stores: StoresSettings) {
        if (context.state.currentAccount) {
            await accountService.updateStores(context.state.currentAccount, stores)
        }
    }
}
