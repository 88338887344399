import { MutationTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import Site from '@/api/models/Site'
import PriceType from '@/api/models/PriceType'
import Stores from '@/api/models/Stores'
import Account from '@/api/models/Account'
import Vue from 'vue'

export enum RootStateMutations {
    setLoading = 'setLoading',
    setConnection = 'setConnection',
    setSites = 'setSites',
    setAccount = 'setAccount',
    setAccounts = 'setAccounts',
    addAccount = 'addAccount',
    updateAccount = 'updateAccount',
    setPriceTypes = 'setPriceTypes',
    setStores = 'setStores'
}

export const mutations: MutationTree<State> = {
    [RootStateMutations.setLoading] (state: State, isLoading: boolean) {
        state.loading = isLoading
    },
    [RootStateMutations.setConnection] (state: State, connection: Connection) {
        state.connection = connection
    },
    [RootStateMutations.setSites] (state: State, sites: Array<Site>) {
        state.sites = sites
    },
    [RootStateMutations.setAccount] (state: State, account: number) {
        state.currentAccount = account
    },
    [RootStateMutations.setAccounts] (state: State, accounts: Array<Account>) {
        state.accounts = accounts
    },
    [RootStateMutations.addAccount] (state: State, account: Account) {
        state.accounts.push(account)
    },
    [RootStateMutations.updateAccount] (state: State, account: Account) {
        const index = state.accounts.findIndex(value => {
            return value.id === account.id
        })
        Vue.set(state.accounts, index, account)
    },
    [RootStateMutations.setPriceTypes] (state: State, priceTypes: Array<PriceType>) {
        state.priceTypes = priceTypes
    },
    [RootStateMutations.setStores] (state: State, stores: Stores) {
        state.stores = stores
    }
}
