import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { actions } from '@/store/actions'
import { mutations } from '@/store/mutations'
import { getters } from '@/store/getters'
import { installerStore } from '@/modules/installer/store'
import Connection from '@/api/models/Connection'
import Site from '@/api/models/Site'
import PriceType from '@/api/models/PriceType'
import Stores from '@/api/models/Stores'
import Account from '@/api/models/Account'

export interface State {
    loading: boolean;
    connection: Connection;
    accounts: Array<Account>;
    currentAccount: number;
    sites: Array<Site>;
    priceTypes: Array<PriceType>;
    stores: Stores;
}

const rootState: State = {
    loading: false,
    connection: {
        apiUrl: '',
        apiKey: '',
        isActive: false,
        accounts: []
    },
    currentAccount: 0,
    accounts: [],
    sites: [],
    priceTypes: [],
    stores: { system: [], ozon: [] }
}

Vue.use(Vuex)

const store: StoreOptions<State> = {
    modules: {
        installer: installerStore
    },
    state: rootState,
    mutations,
    getters,
    actions
}

const state = new Vuex.Store<State>(store)

export default state
