import { GetterTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'
import Site from '@/api/models/Site'
import PriceType from '@/api/models/PriceType'
import SystemStore from '@/api/models/SystemStore'
import OzonStore from '@/api/models/OzonStore'

export const getters: GetterTree<State, State> = {
    isLoading: (state: State): boolean => {
        return state.loading
    },
    connection: (state: State): Connection|undefined => {
        return state.connection
    },
    accounts: (state: State): Array<Account> => {
        return state.accounts
    },
    getCurrentAccount: (state: State): Account|undefined => {
        return state.accounts.find((account: Account) => account.id === state.currentAccount)
    },
    sites: (state: State): Array<Site> => {
        return state.sites
    },
    priceTypes: (state: State): Array<PriceType> => {
        return state.priceTypes
    },
    getSystemStores: (state: State): Array<SystemStore> => {
        return state.stores.system
    },
    getOzonStores: (state: State): Array<OzonStore> => {
        return state.stores.ozon
    }
}
