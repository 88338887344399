import { RouteConfig } from 'vue-router'
import i18n from '@/i18n'

const routes: Array<RouteConfig> = [
    {
        path: '/account/statuses',
        name: 'account_statuses',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/settings/views/Statuses.vue')
    },
    {
        path: '/account/stock',
        name: 'account_stock',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/settings/views/Stock.vue')
    },
    {
        path: '/account/prices',
        name: 'account_prices',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/settings/views/Prices.vue')
    },
    {
        path: '/accounts',
        name: 'accounts',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/settings/views/Accounts.vue')
    }
]

export default routes
