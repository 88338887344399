import { GetterTree } from 'vuex'
import { InstallerState } from '@/modules/installer/store/index'
import { State } from '@/store'
import Status from '@/api/models/Status'

export const getters: GetterTree<InstallerState, State> = {
    getStatuses (state: InstallerState): Array<Status> {
        return state.statuses
    }
}
