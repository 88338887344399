import BaseService from '@/api/services/index'
import Connection from '@/api/models/Connection'

export default class ConnectionService extends BaseService {
    public async signIn (connection: Connection): Promise<Connection> {
        delete connection.accounts

        return await this.api.put<Connection, Connection>(
            this.getUrl('signin'),
            connection
        )
    }

    public async auth (connection: Connection): Promise<Connection> {
        delete connection.accounts

        return await this.api.post<Connection, Connection>(
            this.getUrl('auth'),
            connection
        )
    }

    public async getConnection (): Promise<Connection> {
        return await this.api.get<undefined, Connection>(
            this.getUrl('get_connection')
        )
    }

    public async updateConnection (connection: Connection): Promise<Connection> {
        return await this.api.patch<Connection, Connection>(
            this.getUrl('update_connection'),
            connection
        )
    }
}
