import axios, { AxiosInstance } from 'axios'
import qs from 'qs'

export interface ApiService{
    get<Req, Res>(url: string, data?: Req): Promise<Res>;
    post<Req, Res>(url: string, data: Req): Promise<Res>;
    put<Req, Res>(url: string, data: Req): Promise<Res>;
    patch<Req, Res>(url: string, data: Req): Promise<Res>;
}

const client = axios.create({
    withCredentials: true,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json; charset=utf8'
    },
    transformResponse: (data) => {
        return JSON.parse(JSON.stringify(data))
    }
})

export default class Service implements ApiService {
    private client: AxiosInstance;

    constructor () {
        this.client = client
    }

    public async get<Request, Response> (url: string, data?: Request): Promise<Response> {
        const response = await this.client.get<Response>(url, {
            params: data,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
        })

        return response.data
    }

    public async patch<Request, Response> (url: string, data: Request): Promise<Response> {
        const response = await this.client.patch<Response>(url, data)

        return response.data
    }

    public async post<Request, Response> (url: string, data: Request): Promise<Response> {
        const response = await this.client.post<Response>(url, data)

        return response.data
    }

    public async put<Request, Response> (url: string, data: Request): Promise<Response> {
        const response = await this.client.put<Response>(url, data)

        return response.data
    }
}
