import BaseService from '@/api/services/index'
import Site from '@/api/models/Site'
import Status from '@/api/models/Status'
import PriceType from '@/api/models/PriceType'
import Stores from '@/api/models/Stores'

export default class ReferenceService extends BaseService {
    public async sitesList (): Promise<Array<Site>> {
        return await this.api.get<never, Array<Site>>(
            this.getUrl('reference_sites')
        )
    }

    public async statusesList (): Promise<Array<Status>> {
        return await this.api.get<never, Array<Status>>(
            this.getUrl('reference_statuses')
        )
    }

    public async priceTypesList (): Promise<Array<PriceType>> {
        return await this.api.get<never, Array<PriceType>>(
            this.getUrl('reference_price_types')
        )
    }

    public async storeList (accountId: number): Promise<Stores> {
        return await this.api.get<never, Stores>(
            this.getUrl('reference_stores', { accountId })
        )
    }
}
